import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router';
export default function(siteInfo,isDev){
    let homePage = siteInfo.domainRule.guideHomePage || "/start.html"
    if(isDev){
        homePage = "/start.html"
    }

    return createRouter({
        history: createWebHistory(),
        routes:[
            {
                path: '/',
                name: 'App',
                redirect: { name: 'Start' },
            },{
                path: homePage,
                name: 'Start',
                component: () => import('@/start/pages/start.vue')
            }
        ]
    })
}