<template>
  <router-view :key="urlKey" v-if="$store.state.siteInfo.id && urlKey"></router-view>
</template>
<script setup>
import { watch,reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick } from "vue";
import { useRouter } from 'vue-router';
const data = reactive({
	urlKey: 0
})
const { urlKey } = toRefs(data)


const useRouterCurrent = reactive(useRouter());
watch(useRouterCurrent,(n,o)=>{
	reload()
})

const reload = ()=>{
	data.urlKey = Math.random() * 1000	
}

onMounted(() => {
	reload()
})
</script>