<template>
	<div class="page" v-if="total > 1">
		<ul>
			<li>共 <span>{{total}}</span> 项</li>
			<li @click="$emit('fn',pg.first)"><a></a></li>
			<li @click="$emit('fn',pg.prev)"><a></a></li>
			<template v-for="(vo,idx) in pg.list" :key="idx">
				<li class="on" v-if="vo == page"> <strong>{{page}}</strong> </li>
				<li :class="{'show':pg.show.indexOf(vo) != -1}" @click="$emit('fn',vo)" v-else> <a>{{vo}}</a> </li>
			</template>
			<li @click="$emit('fn',pg.next)"><a></a></li>
			<li @click="$emit('fn',pg.last)"><a></a></li>
		</ul>
	</div>
</template>
<script setup>
import { computed,reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick,defineProps } from "vue";
import { useStore } from "vuex";
import { useRouter,useRoute } from "vue-router";
const currentInstance = getCurrentInstance()
const { $ajax } = currentInstance.appContext.config.globalProperties
const $router = useRouter();
const $route = useRoute()
const store = useStore();

// data
// const data = reactive({})
// const {listData,classInfo} = toRefs(data)
const props = defineProps({
    // text:String,
    // message:Number
    total:{
		// type:Number,
		default:function() {
			return 0
		}
	},
	page:{
		// type:Number,
		default:function() {
			return 1
		}
	},
	limit:{
		// type:Number,
		default:function() {
			return 0
		}
	}
})

let pg = computed(function(){
	let pages  = Number(props.page || 1)
	let start = pages - 2;
	let end   = pages + 2;
	let max = Math.floor(props.total / props.limit)
	
	if(end < 5){
		end = 5
	}
	if(max < 5){
		end = max
	}else if(end > max) end = max;

	if(start > (max - 5)){
		start = max - 5
	}
	if(start < 1) start = 1;

	let res = []
	for(let i=start;i<=end;i++){
		res.push(i)
	}


	let resp = {
		first:1,
		prev:(pages - 1) > 0 ? (pages - 1):1,
		list:res,
		next:(pages + 1) <= max ? (pages + 1):max,
		last:max,
		show:[],
	}
	let show = []
	if(pages == 1){
		show = [1,2,3]
	}else if(pages == max){
		show = [max-2,max-1,max]
	}else{
		show = [resp.prev,pages,resp.next]
	}
	resp.show = show
	return resp
})
</script>