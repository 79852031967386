
import { createApp } from 'vue'
import App from '@/start/App.vue'
import init from '@/lib/js/public.js'
import router from '@/start/router' 

import '@/start/assets/style.css'

const app = createApp(App)
//按需进行请求挂载
init(app,{
    "protocol": "",
    "hostname": "",
    "port": 0
}).then(res=>{
    app.use(res.store).use(router(res.data || {},process.env.NODE_ENV=='development')).mount('#app_start')
}).catch(err=>{
    alert(err)
})