function encodeApiResult(data){
    if(typeof data == "string" && data.length > 0){
        try{
	        let temp  = data.substr(3) || ""
            temp = atob(temp)
            temp = decodeURIComponent(temp)
        	// temp = temp.replace(/\n/g,"\\n").replace(/\r/g,"\\r")
            return JSON.parse(temp)
        }catch(e){
            return data
        }
    }
    return data
}
export default function($ajax) {
	return {
		getSiteInfo() { // 站点信息，包含广告
			return $ajax.get("/api/global").then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getClassList(params) { // 分类列表
			return $ajax.get("/api/class/list",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getClassInfo(params) { // 分类详细
			return $ajax.get("/api/class",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getVodList(params) { // 视频列表
			return $ajax.get("/api/vod/list",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getVodInfo(id,params) { // 视频详细
			return $ajax.get("/api/vod/"+id,{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getVodSituation(params) { // 视频详细
			return $ajax.get("/api/total",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getVodPlayLineList(vid,params) { // 视频播放线路
			return $ajax.get("/api/vod/playline/"+vid,{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getVodTagList(params) { // 视频标签
			return $ajax.get("/api/vod/taglist",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getFilterVodList(params) { // 视频列表
			return $ajax.get("api/vod/filter/list",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getVodFilterList(params) { // 视频自定义筛选项
			return $ajax.get("/api/vod/filter",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getNovelCharterInfo(params) { // 小说章节详细
			return $ajax.get("/api/fictions/charter",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getNovelCharterList(params) { // 小说章节列表
			return $ajax.get("/api/fictions/charter/list",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getNovelList(params) { // 小说列表
			return $ajax.get("/api/fictions/list",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getNovelInfo(id,params) { // 小说详细
			return $ajax.get("/api/fictions/"+id,{params:params}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getImageList(params) { // 图集列表
			return $ajax.get("/api/images/list",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getImageInfo(id,params) { // 图集详细
			return $ajax.get("/api/images/"+id,{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getStarList(params) { // 名人列表
			return $ajax.get("/api/actor/list",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getStarInfo(id,params) { // 名人详细
			return $ajax.get("/api/actor/"+id,{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getAlbumList(params) { // 专辑列表
			return $ajax.get("/api/album/list",{params:params || {}}).then(res=>{
				res.data =  encodeApiResult(res.data)
				return res
			})
		},
		getAppVersion() { // app版本信息
			return $ajax.get("/static/app/version.json")
		},
	}
}