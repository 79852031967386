// 缓存处理
/*
 * 增强方法，获取年月日时分秒，多种格式
 * example: formatDate('yy-mm-dd hh:ii:ss', 1486623999);
 *          formatDate('yy年mm月dd日 hh时ii分ss秒', 1486623999);
 *          formatDate('yy年mm月dd日', 1486623999);
 *          or more use method...
 * */
const formatDate = function() {
    var dateType = 'yy-mm-dd hh:ii:ss',
        dateTime = new Date().getTime(),
        reg_xx,
        reg_x,
        xx = '',
        x = '';

    if (arguments.length == 1) {
        if (typeof arguments[0] == 'string') {
            dateType = arguments[0];
        } else {
            dateTime = arguments[0];
        }
    } else {
        dateType = arguments[0];
        dateTime = arguments[1];
    }

    if (String(dateTime).length <= 10) {
        dateTime = parseInt(dateTime) * 1000;
    } else {
        dateTime = parseInt(dateTime);
    }
    dateTime = new Date(dateTime);

    /*format: yyyy*/
    reg_xx = /yy{1}/gi;
    reg_x = /y{1}/gi;
    if (reg_xx.test(dateType)) {
        xx = dateTime.getFullYear();
        dateType = dateType.replace(reg_xx, xx);
    } else if (reg_x.test(dateType)) {
        x = dateTime.getFullYear();
        dateType = dateType.replace(reg_x, x);
    }

    /*format: mm|m*/
    reg_xx = /mm{1}/gi;
    reg_x = /m{1}/gi;
    if (reg_xx.test(dateType)) {
        xx = dateTime.getMonth() + 1 > 9 ? dateTime.getMonth() + 1 : '0' + (dateTime.getMonth() + 1);
        dateType = dateType.replace(reg_xx, xx);
    } else if (reg_x.test(dateType)) {
        x = dateTime.getMonth() + 1;
        dateType = dateType.replace(reg_x, x);
    }

    /*format: dd|d*/
    reg_xx = /dd{1}/gi;
    reg_x = /d{1}/gi;
    if (reg_xx.test(dateType)) {
        xx = dateTime.getDate() > 9 ? dateTime.getDate() : '0' + dateTime.getDate();
        dateType = dateType.replace(reg_xx, xx);
    } else if (reg_x.test(dateType)) {
        x = dateTime.getDate();
        dateType = dateType.replace(reg_x, x);
    }

    /*format: hh|h*/
    reg_xx = /hh{1}/gi;
    reg_x = /h{1}/gi;
    if (reg_xx.test(dateType)) {
        xx = dateTime.getHours() > 9 ? dateTime.getHours() : '0' + dateTime.getHours();
        dateType = dateType.replace(reg_xx, xx);
    } else if (reg_x.test(dateType)) {
        x = dateTime.getHours();
        dateType = dateType.replace(reg_x, x);
    }

    /*format: ii|i*/
    reg_xx = /ii{1}/gi;
    reg_x = /i{1}/gi;
    if (reg_xx.test(dateType)) {
        xx = dateTime.getMinutes() > 9 ? dateTime.getMinutes() : '0' + dateTime.getMinutes();
        dateType = dateType.replace(reg_xx, xx);
    } else if (reg_x.test(dateType)) {
        x = dateTime.getMinutes();
        dateType = dateType.replace(reg_x, x);
    }

    /*format: ss|s*/
    reg_xx = /ss{1}/gi;
    reg_x = /s{1}/gi;
    if (reg_xx.test(dateType)) {
        xx = dateTime.getSeconds() > 9 ? dateTime.getSeconds() : '0' + dateTime.getSeconds();
        dateType = dateType.replace(reg_xx, xx);
    } else if (reg_x.test(dateType)) {
        x = dateTime.getSeconds();
        dateType = dateType.replace(reg_x, x);
    }

    return dateType;
}
export default {
    format:formatDate
};
