<template>
	<div :id="domID" v-if="show"></div>
</template>
<script setup>
import md5 from 'md5'
import {  reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick,watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const currentInstance = getCurrentInstance()
const { $ajax } = currentInstance.appContext.config.globalProperties
const router = useRouter();
const store = useStore();
const props = defineProps({
	position:String
})

// data
const data = reactive({
	domID:md5(props.position + Math.random() * 10),
	limit:0,
	show:true
})
const { domID,show } = toRefs(data)

// FN:启动页
const doPos = ()=>{
	if (props.position) {
		let arr = store.state.sitePub[props.position] || []
		let tmp = JSON.parse(JSON.stringify(arr))
		if (tmp.length > 0) {
			try{
				renderData(tmp.filter(v => v.state == 1),data.domID)
			}catch(e){
				console.log(props.position,e.message,tmp)
			}
		}else{
			data.show = false
		}
	}else{
		data.show = false
	}
}
onMounted(() => {
	doPos()
})
</script>